.contact-us  input::-webkit-.contact-us  input-placeholder, textarea::-webkit-.contact-us  input-placeholder {
    color: #FAFAFA;
    
  }
  
  .contact-us  input:focus::-webkit-.contact-us  input-placeholder, textarea:focus::-webkit-.contact-us  input-placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input::-moz-placeholder, textarea::-moz-placeholder {
    color: #FAFAFA;
 
  }
  
  .contact-us  input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input::placeholder, textarea::placeholder {
    color: #FAFAFA;

  }
  
  .contact-us  input:focus::placeholder, textarea::focus:placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input::-ms-placeholder, textarea::-ms-placeholder {
    color: #FAFAFA;
   
  }
  
  .contact-us  input:focus::-ms-placeholder, textarea:focus::-ms-placeholder {
    color: #FAFAFA;
  }
  
  /* on hover placeholder */
  
  .contact-us  input:hover::-webkit-.contact-us  input-placeholder, textarea:hover::-webkit-.contact-us  input-placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover:focus::-webkit-.contact-us  input-placeholder, textarea:hover:focus::-webkit-.contact-us  input-placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover::-moz-placeholder, textarea:hover::-moz-placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover:focus::-moz-placeholder, textarea:hover:focus::-moz-placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover::placeholder, textarea:hover::placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover:focus::placeholder, textarea:hover:focus::placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover::placeholder, textarea:hover::placeholder {
    color: #FAFAFA;
  }
  
  .contact-us  input:hover:focus::-ms-placeholder, textarea:hover::focus:-ms-placeholder {
    color: #FAFAFA;
  }
  

  .contactForm {
    width: auto;
  }
 
  
  .contact-us  input {
    font-family: 'Lato', sans-serif;
    width: 50%;
    height: 50px;
    padding: 0px 15px 0px 15px;
    margin-left:20px;
    background: rgb(23, 37, 67,0.6);
    backdrop-filter: saturate(185%) blur(5px);
    outline: none;
    color: white;
    margin-bottom: 10px;

    border-bottom: solid 1px #B7DBE7;
    border-top: none;
    border-left: none;
    border-right: none;

    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }
  
  .contact-us  input:hover {
    background: rgb(23, 37, 67, 0.9);
    backdrop-filter: saturate(185%) blur(5px);
    color: #FAFAFA;
  }
  
  .contact-us textarea {
    width: 50%;
    padding: 15px;
    margin-left: 20px;
    background: 	rgb(23, 37, 67,0.6);
    backdrop-filter: saturate(185%) blur(5px);
    outline: none;
    
    color: white;
    font-family: 'Lato', sans-serif;
    
    border-bottom: solid 1px #B7DBE7;
    border-top: none;
    border-left: none;
    border-right: none;
    
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }
  
  .contact-us textarea:hover {
    background: rgb(23, 37, 67, 0.9);
    backdrop-filter: saturate(185%) blur(5px);
    color: #FAFAFA;
  }
  
  #submit {
    width: 502px;
    padding: 0;
    font-family: 'Lato', sans-serif;
    outline:none;
    cursor: pointer;
    color: #FAFAFA;
  }
  

  .submit {
    color: #FAFAFA !important;
    margin-top: 10px;
    background: 	rgb(93, 70, 130,0.8) !important;
    backdrop-filter: saturate(185%) blur(5px);
    border:1px solid #5D4682 !important;
  }
  .submit:hover {
    background-color: rgb(93, 70, 130) !important;
    backdrop-filter: saturate(185%) blur(5px);
  }

  .contactShape {
      float: right;
      width: 30vw;
      margin-bottom: -400px;
  }
  @media screen and (max-width: 650px) {
    .contact-us  input {
        width: 80%;
        margin-left: none !important;
    }
    .contactForm{
      text-align: center;
    }
    textarea {
        width: 80% !important;
        max-width: 80% !important;
        min-width: 80% !important;
        margin-left: none !important;
    }
    .contactShape{
        width: 40vw !important;
        margin-bottom: -900px !important;
    }
}
 