.MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

:root {
  --amplify-primary-color: #206a5d;
  --amplify-primary-tint: #81b214;
  --amplify-primary-shade: #60860e;
}



@media only screen and (max-width: 671px) {
  .custom-tc {
    position: relative;
    top: -12.2em;
    padding: 10px;
  }
}

@media only screen and (min-width: 672px) {
  .custom-tc {
    position: relative;
  top: -10.8em;
    padding: 0px;
  }
  .custom-tc div {
    width: 80%;
    margin: 0 auto;
  }
  .secondaryFooter {
    margin-top: 3em;
  }
}
@media only screen and (max-width: 475px) {
  .custom-tc {
    position: relative;
    top: -12.3em;
    padding: 5px;
  }
  .hide-in-mobile {
    display: none !important;
  }
}
.joinNow {
  background-color: #f38d64 !important;
  color: #000000 !important;
}
.remark-md table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

.remark-md table td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

.remark-md table tr:nth-child(even){background-color: #f2f2f2;}

.remark-md table tr:hover {background-color: #ddd;}

.remark-md table th {
  padding: 12px;
  padding: 12px;
  text-align: left;
  background-color: #3479c9;
  color: white;
}

.scroll {
  scroll-behavior: smooth;
 }

 a {
  text-decoration: none;
  color: #76a0ae;
}
 
 .otherResources,.fancyLinks a {
  text-decoration: none;
  color: black;
 }
 

 a:hover {
   text-decoration: underline;
 }
 
 #down {
  margin-top: 100%;
  padding-bottom: 25%;
 }

 .cardBox {
  display: inline-block;
  font-size: 1.2em;
  margin: 1%;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 31%;
  
  
}

.cardBox:hover .card {
  transform: rotateY( 180deg);
}

.card {
  background: white;
  cursor: default;
  height: 300px;
  padding-bottom: 20px;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 10px 0 rgba(0, 0, 0, 0.05);

  
}

.card p {
  margin-bottom: 1.8em;
}

.card .front,
.card .back {
  backface-visibility: hidden;
  box-sizing: border-box;
  color: black;
  display: block;
  font-size: 1.2em;
  height: 100%;
  padding: 0px 5px 0px 5px;
  position: absolute;
  text-align: center;
  width: 100%;
  

}

.card .front strong {
  background: #fff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}

.card .back {
  transform: rotateY( 180deg);
}

.card .back a {
  padding: 0.3em 0.5em;
  background: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 1px;
  font-size: 0.9em;
  transition: all 0.2s ease 0s;
}

.card .back a:hover {
  background: #fff;
  color: #333;
  text-shadow: 0 0 1px #333;
}

.cardBox:nth-child(1) .card .back {
  background: #e7f0f4;
}

.cardBox:nth-child(2) .card .back {
  background: #e7f0f4;
}

.cardBox:nth-child(3) .card .back {
  background: #e7f0f4;
}

.cardBox:nth-child(4) .card .back {
  background: #e7f0f4;
}

.cardBox:nth-child(2) .card {
  -webkit-animation: giro 1.5s 1;
  animation: giro 1.5s 1;
}

.cardBox:nth-child(3) .card {
  -webkit-animation: giro 2s 1;
  animation: giro 2s 1;
}

.cardBox:nth-child(4) .card {
  -webkit-animation: giro 2.5s 1;
  animation: giro 2.5s 1;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY( 180deg);
  }
  to {
    transform: rotateY( 0deg);
  }
}

@keyframes giro {
  from {
    transform: rotateY( 180deg);
  }
  to {
    transform: rotateY( 0deg);
  }
}

@media screen and (max-width: 767px) {
  .cardBox {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 46%;
  }
  .card {
    height: 285px;
  }
  .cardBox:last-child {
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 480px) {
  .cardBox {
    width: 94.5%;
  }
  .card {
    height: 260px;
  }
}

.boxesContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 750px) {
  .headContainer {
    display: none !important;
  }
  .card {
    height: 350px;
  }
  .boxesContainer {
    display: block;
    justify-content: center;
  }
}

@media screen and (min-width: 751px) and (max-width: 1054px) {
  .cardBox {
    width: 30.3%;
    margin: 10px;
  }
  .card {
    height: 350px;
  }
}


@media screen and (min-width: 750px) {
  .headContainerMobile {
    display: none !important;
  }

}

.front {
  display: flex !important;
  flex-direction: column;
}
.boxArrow svg {
  align-self: flex-end;
}


.boxArrow {
  color: #d2dec8;
  flex: 1;
  display: flex;
  padding-bottom: 1em;
  padding-right: 1em;
  text-align: right;
  justify-content: end;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.containerResource {
  display: flex;
  flex-flow: row;
  width: 90%;
  height: 30vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.hover-container {
  text-align: center;
  margin: 0em 2em 2em 2em;
}


.linkText {
  border-bottom: 3px solid #98D4B5;
  font-size: 1.5em;
  transition: all 0.25s linear;
  position: relative;
}

.linkText:before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #B7DBE7;
  position: absolute;
  left: 0;
  bottom: -3px; /* this is to match where the border is */
  transform-origin: left; 
  transform: scale(0);
  transition: 0.9s linear;
/*   will-change: transform; */
}

.linkText:hover:before {
  transform: scale(1);
}

@media screen and (max-width: 1025px) {
  .fancyLinks {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .normalLinks {
    display: none !important;
  }
}

.otherResources {
  list-style-type: none;
  padding: 0px;
}

a.resourcesMobile:link {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

a.resourcesMobile:visited {
  text-decoration: none;
  font-weight: bold;
  color: black;

}

a.resourcesMobile:hover {
  text-decoration: underline;
  font-weight: bold;
  color: black;

}

a.resourcesMobile:active {
  text-decoration: underline;
  font-weight: bold;
  color: black;

}


.chat-box {
  z-index: 1001;
  position: fixed;
  bottom: 0;
  right: 10px;
  width: 320px;
  max-width: 100vw;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
}

.chat-box-header {
  z-index: 1000;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #101828;
}

.chat-box-header-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.chat-box-header-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-box-message .rce-mbox-left-notch {
  fill: #172543;
}

.chat-box-message .rce-mbox-right-notch {
  fill: #21345f;
}

.chat-box-scroll-style::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

.chat-box-scroll-style::-webkit-scrollbar-thumb {
  background-color: #283247; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners of the thumb */
}

.chat-box-scroll-style::-webkit-scrollbar-thumb:hover {
  background-color: #536fac; /* Color of the thumb on hover */
}

.chat-box-scroll-style::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}


.minimized {
  height: 50px;
}

.minimize-btn {
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
}


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200&display=swap');