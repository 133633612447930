.program-editor {
  min-height: 5em;
}

.npm__react-simple-code-editor__textarea {
  padding-top: 10px !important;
  padding-bottom: 10px !important;

}

.editor pre {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}