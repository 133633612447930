
.faqShape {
    width: 40%;
    float: right;
    margin-right: 80px;
    margin-top: -50px;
}

.accordianFAQ {
    width: 50%;
    margin-left: 80px;
    padding: 10px;
}
.faqHeading {
    font-size: 50px;
    text-align: center;

}
.contactButton {
    border: 1px solid;
    border-radius: 20px;
    padding: 10px 20px 10px 20px;
    border-color: #B7DBE7;
    color: #FAFAFA !important;
    background-color: #B7DBE7;
    font-size: 20px;
    display: inline-block;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 10px 0 rgba(160, 154, 154, 0.05);
}
.moreQuestions {
    margin-left: 80px;
}
@media screen and (max-width: 1025px) {
    .faqShape {
        width: 50vw;
        margin: 0px !important;
    }
    .accordianFAQ {
        margin: 0px !important;
    }
}
@media screen and (max-width: 825px) {
    .faqShape {
        display: none;
    }
    .accordianFAQ {
        margin-left: 0px !important;
        width: 100%;
    }
}